<template>
  <div
    class="modal fade"
    id="modalNotifica"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-dialog-centered modal_custom"
      role="document"
    >
      <div class="modal-content container">
        <ValidationObserver
          tag="form"
          ref="form"
          class="modal-body px-4 py-4"
          @submit.prevent="sendNotification()"
        >
          <p class="titulo_dash mb-4">Adicionar notificação</p>
          <topo
            :title.sync="superObjt.title"
            :description.sync="superObjt.description"
          />
          <check
            :visible.sync="superObjt.visible"
            :startDate.sync="superObjt.startDate"
            :endDate.sync="superObjt.endDate"
            :type.sync="superObjt.type"
          />
          <unidades ref="unidades" />
          <div class="col-md-4 ml-auto">
            <button
              class="btn_default d-flex justify-content-center align-items-center"
              :disabled="disabled"
              type="submit"
            >
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
                v-show="disabled"
              ></span>
              <i class="fas fa-plus-circle mr-1" v-show="!disabled"></i>
              {{ !disabled ? `${id ? "Editar" : "Criar"}` : "" }}
            </button>
          </div>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import topo from "./modal/topo";
import check from "./modal/checkBox";
import unidades from "./modal/unidades";
import HTTP from "@/api_system";
export default {
  components: {
    topo,
    check,
    unidades
  },
  props: ["id"],
  data() {
    return {
      superObjt: {
        title: "",
        description: "",
        visible: true,
        tenantsJson: [],
        startDate: "",
        endDate: "",
        type: 1
      },
      disabled: false
    };
  },
  created() {},
  mounted() {
    var self = this;
    $("#modalNotifica").on("hide.bs.modal", function(e) {
      self.superObjt = {
        title: "",
        description: "",
        visible: true,
        tenantsJson: [],
        startDate: "",
        endDate: "",
        type: 1
      };
      self.$refs.form.reset();
    });
  },
  methods: {
    sendNotification() {
      this.$refs.form.validate().then(success => {
        if (success) {
          this.$refs.unidades.unidades.forEach(element => {
            this.$refs.unidades.checkboxUnidade.forEach(item => {
              if (
                element.city == item.split("|")[0] &&
                element.reference == item.split("|")[2]
              ) {
                console.log("elemento aqui", element);
                this.superObjt.tenantsJson.push({
                  tenantId: element.id,
                  tenanancyName: element.tenancyName
                });
              }
            });
          });
          this.send();
        }
      });
    },
    send() {
      this.disabled = true;
      HTTP.post(`services/app/Alert/CreateAlertFromMaster`, this.superObjt, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.getterToken}`
        }
      })
        .then(response => {
          this.disabled = false;
          $("#modalNotifica").modal("hide");
          this.$notify({
            group: "erros",
            type: "sucess",
            text: `<i class="icon ion-close-circled"></i>Notificação criada!`
          });
        })
        .catch(error => {
          this.disabled = false;
          this.$notify({
            group: "erros",
            type: "error",
            text: `<i class="icon ion-close-circled"></i>Ops algo deu errado`
          });
        });
    }
  },
  watch: {
    id: function() {
      this.id;
    }
  }
};
</script>

<style scoped>
.fa-plus-circle {
  font-size: 20px;
}
.btn_default {
  background-color: #e0a638;
  height: 40px;
  border: none;
}
.btn_default:hover {
  background-color: #f38235;
}
.modal_custom {
  max-width: 620px;
}

.modal-content {
  border-radius: 20px;
}

.titulo_dash {
  color: #2474b2;
  font-weight: 700;
}
.btn_default_excluir {
  background-color: #e67979;
  height: 40px;
  border: none;
}
.btn_default_excluir:hover {
  background-color: #dc3545;
}
.btn_default_disabled {
  background-color: #e3e3e3;
}
</style>

<template>
	<div class="chebox my-3">
		<div class="box w-100">
			<div class="col-md-12">
				<div class="row">
					<div class="col-md-12 ">
						<div class="col-md-12 my-3">
							<div class="row align-items-center">
								<div class="col-md-5">
									<p class="titulo_dash mb-0 category">Unidades</p>
								</div>
								<div class="col-md-4 ml-auto">
									<div class="d-flex align-items-center justify-content-end">
										<p class="titulo_dash mb-0 select_all text-right mr-1">Selecionar todos</p>
										<div class="checkbox">
											<input
												type="checkbox"
												id="selectAllCategory"
												name="selectAllCategory"
												value="true"
												@change.prevent="selectAllState()"
												:checked="checkboxEstado.length == estados.length && checkboxCidade.length == cidades.length && checkboxUnidade.length == unidades.length"
											/>
											<label for="selectAllCategory"></label>
										</div>
									</div>
								</div>
								<div class="col-md-12" >
									<hr />
								</div>
							</div>
							<div class="col-md-12" id="optionUnity">
								<div class="row">
									<div class="col-md-4 mb-1">
										<div class="d-flex align-items-center mb-2">
											<div class="checkbox mr-2">
												<input
													type="checkbox"
													id="todosEstaos"
													name="todosEstaos"
													@change.prevent="selectAllState()"
													:checked="checkboxEstado.length == estados.length"
												/>
												<label for="todosEstaos"></label>
											</div>
											<p class="mb-0 list">Todos Estados</p>
										</div>
										<div v-for="(item ,index) in estados" :key="index" class="d-flex align-items-center mb-2">
											<div class="checkbox mr-2">
												<input
													type="checkbox"
													:value="item.state"
													:id="`estado${index}`"
													:name="`estado${index}`"
													v-model="checkboxEstado"
													@change.prevent="selectEstado($event.target.value)"
												/>
												<label :for="`estado${index}`"></label>
											</div>
											<p class="mb-0 list">{{getEstados(item.state)}}</p>
										</div>
									</div>
									<div class="col-md-4 mb-1">
										<div class="d-flex align-items-center mb-2">
											<div class="checkbox mr-2">
												<input
													type="checkbox"
													id="todasCidades"
													name="todasCidades"
													@change.prevent="selectAllState()"
													:checked="checkboxCidade.length == cidades.length"
												/>
												<label for="todasCidades"></label>
											</div>
											<p class="mb-0 list">Todas Cidades</p>
										</div>
										<div v-for="(item ,index) in cidades" :key="index" class="d-flex align-items-center mb-2">
											<div class="checkbox mr-2">
												<input
													type="checkbox"
													:value="`${item.city}|${item.state}`"
													:id="`cidade${index}`"
													:name="`cidade${index}`"
													v-model="checkboxCidade"
													:checked="checkboxCidade"
												/>
												<label :for="`cidade${index}`"></label>
											</div>
											<p class="mb-0 list">{{item.city}}</p>
										</div>
									</div>
									<div class="col-md-4 mb-1">
										<div class="d-flex align-items-center mb-2">
											<div class="checkbox mr-2">
												<input
													type="checkbox"
													id="todasUnidades"
													name="todasUnidades"
													@change.prevent="selectAllState()"
													:checked="checkboxUnidade.length == unidades.length"
												/>
												<label for="todasUnidades"></label>
											</div>
											<p class="mb-0 list">Todas Unidades</p>
										</div>
										<div
											v-for="(item ,index) in unidades"
											:key="index"
											class="d-flex align-items-center mb-2"
										>
											<div class="checkbox mr-2">
												<input
													type="checkbox"
													:value="`${item.city}|${item.state}|${item.reference}`"
													:id="`unidades${index}`"
													:name="`unidades${index}`"
													v-model="checkboxUnidade"
													@change="selectUnidade($event.target.value)"
												/>
												<label :for="`unidades${index}`"></label>
											</div>
											<p class="mb-0 list">{{item.reference}}</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import HTTP from "@/api_system";
	export default {
		data() {
			return {
				unidades: [],
				uf: [
					{ nome: "Acre", sigla: "AC" },
					{ nome: "Alagoas", sigla: "AL" },
					{ nome: "Amapá", sigla: "AP" },
					{ nome: "Amazonas", sigla: "AM" },
					{ nome: "Bahia", sigla: "BA" },
					{ nome: "Ceará", sigla: "CE" },
					{ nome: "Distrito Federal", sigla: "DF" },
					{ nome: "Espírito Santo", sigla: "ES" },
					{ nome: "Goiás", sigla: "GO" },
					{ nome: "Maranhão", sigla: "MA" },
					{ nome: "Mato Grosso", sigla: "MT" },
					{ nome: "Mato Grosso do Sul", sigla: "MS" },
					{ nome: "Minas Gerais", sigla: "MG" },
					{ nome: "Pará", sigla: "PA" },
					{ nome: "Paraíba", sigla: "PB" },
					{ nome: "Paraná", sigla: "PR" },
					{ nome: "Pernambuco", sigla: "PE" },
					{ nome: "Piauí", sigla: "PI" },
					{ nome: "Rio de Janeiro", sigla: "RJ" },
					{ nome: "Rio Grande do Norte", sigla: "RN" },
					{ nome: "Rio Grande do Sul", sigla: "RS" },
					{ nome: "Rondônia", sigla: "RO" },
					{ nome: "Roraima", sigla: "RR" },
					{ nome: "Santa Catarina", sigla: "SC" },
					{ nome: "São Paulo", sigla: "SP" },
					{ nome: "Sergipe", sigla: "SE" },
					{ nome: "Tocantins", sigla: "TO" }
				],
				checkboxEstado: [],
				checkboxCidade: [],
				checkboxUnidade: []
			};
		},
		mounted() {
			this.getUnidades();
		},
		methods: {
			getUnidades() {
				this.load = true;
				HTTP.post("services/app/Tenant/ListOfTenants", "", {
					headers: {
						Authorization: `Bearer ${this.$store.getters.getterToken}`
					}
				})
					.then(response => {
						this.unidades = response.data.result.activeTenants;

						this.selectAllState();
						this.load = false;
					})
					.catch(error => {
						this.load = false;
					});
			},
			getEstados(e) {
				var nome;
				this.uf.forEach(item => {
					if (e.toLowerCase() == item.sigla.toLowerCase()) {
						nome = item.nome;
					}
				});
				return nome;
			},
			selectEstado(ev) {
				var array = [...this.checkboxCidade];
				var find = this.checkboxEstado.findIndex(e => e == ev);
				this.cidades.forEach((element, index) => {
					if (element.state == ev && find > -1) {
						array.push(`${element.city}|${element.state}`);
						// this.checkboxCidade.push(`${element.city}|${element.state}`);
					} else if (element.state == ev && find == -1) {
						var teste = array.findIndex(
							el => el == `${element.city}|${element.state}`
						);

						array.splice(teste, 1);
					}
				});
				this.checkboxCidade = array;
			},
			selectUnidade(ev) {
				var find = this.checkboxUnidade.findIndex(e => e == ev);
				if (find < 0) {
					var array = [];
					this.checkboxCidade.forEach(item => {
						if (item != `${ev.split("|")[0]}|${ev.split("|")[1]}`) {
							array.push(item);
						}
					});
					this.checkboxCidade = array;
				}
			},
			selectAllState() {
				var array = [];
				var arr = [];
				this.estados.forEach(item => {
					array.push(item.state);
				});
				this.checkboxEstado = array;
				this.cidades.forEach(item => {
					arr.push(`${item.city}|${item.state}`);
				});
				this.checkboxCidade = arr;
			}
		},
		watch: {
			checkboxEstado: function(val) {},
			checkboxCidade: function(val) {
				this.checkboxCidade.forEach(item => {
					var i = this.checkboxEstado.findIndex(e => e == item.split("|")[1]);
					if (i < 0) {
						this.checkboxEstado.push(item.split("|")[1]);
					}
				});
				var array = [];
				this.checkboxEstado.forEach((item, index) => {
					var find = this.checkboxCidade.findIndex(e => e.split("|")[1] == item);
					if (find > -1) {
						array.push(item);
					}
				});
				this.checkboxEstado = array;
				if (val) {
					var arr = [];
					this.checkboxCidade.forEach(item => {
						this.unidades.forEach(element => {
							if (`${element.city}|${element.state}` == item) {
								arr.push(`${element.city}|${element.state}|${element.reference}`);
							}
						});
					});
					this.checkboxUnidade = arr;
				}
			},
			checkboxUnidade: function() {
				var array = [];

				this.checkboxUnidade.forEach((item, index) => {
					var i = this.checkboxCidade.findIndex(
						e => e == `${item.split("|")[0]}|${item.split("|")[1]}`
					);
					if (i < 0) {
						this.checkboxCidade.push(
							`${item.split("|")[0]}|${item.split("|")[1]}`
						);
					}
				});
			}
		},
		computed: {
			estados() {
				var array = [];
				this.unidades.forEach(item => {
					var i = array.findIndex(e => e.state == item.state);
					if (i < 0) {
						array.push({ state: item.state });
					}
				});

				return array;
			},
			cidades() {
				var array = [];
				this.unidades.forEach(item => {
					var i = array.findIndex(e => e.city == item.city);
					if (i < 0) {
						array.push({ city: item.city, state: item.state });
					}
				});
				return array;
			}
		}
	};
</script>

<style scoped>
.border_double {
	border-right: 4px solid #e3e3e3;
	border-right-style: double;
}
.list {
	letter-spacing: 0.5px;
	font-size: 10px;
	color: #868686;
	white-space: nowrap;
	overflow: hidden !important;
	text-overflow: ellipsis;
	max-width: 100%;
}
.box {
	border: 2px solid #e3e3e3;
	border-radius: 20px;
	background-color: #f6f6f6;
}
.category {
	font-size: 12px;
}
.select_all {
	font-size: 10px;
	font-weight: 500 !important;
}
.label_default {
	font-size: 14px;
	color: #2474b2 !important;
	font-weight: 500;
}
.input_default {
	height: 45px;
}
.titulo_dash {
	color: #2474b2;
	font-weight: 700;
}
p.failed {
	color: red;
}
#optionUnity{
	max-height: 200px;
    overflow: auto;
}
</style>

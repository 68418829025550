<template>
  <div class="notificacoes py-5">
    <div class="welcomeMobile" v-if="this.sizeWindow <= 767">
      <router-link to="/" class="imgWelCont">
        <img src="../assets/image/iconesMobile/grayArrow.svg" class="grayArrow">
      </router-link>
      <div class="txtWelCont">
        <p>Notificações</p>
      </div>
    </div>
    <div class="container">
      <div class="col-md-12">
        <div class="col-md-12 my-3">
          <div class="row align-items-center mx-0">
            <div class="col-md-2 px-0">
              <p
                class="mb-0 titulo_dash titles"
                v-if="
                  $store.getters.getUser.profile == 3 && itemsNoti.length > 0
                "
              >
                Banners
              </p>
            </div>
            <div
              class="col-md-2 ml-auto px-0"
              v-if="$store.getters.getUser.profile == 1"
            >
              <button class="btn_default" @click.prevent="showModal()">
                Criar Notificação
              </button>
            </div>
          </div>
        </div>
        <div v-if="$store.getters.getUser.profile == 3">
          <Recados
            v-if="itemsNoti.length > 0"
            :items="itemsNoti"
            @modalImg="imagesCard"
          />
          <!-- <ModalImg :itens="urlImage" :visible="shows" :link="link" /> -->
        </div>
        <transition name="vertical">
          <p
            class="my-5 text-center w-100"
            style="font-size: 16px; color: #ffff"
            v-if="notifications.length == 0"
          >
            Ainda não existem notificações cadastradas
          </p>
        </transition>
        <p
          class="mb-0 titulo_dash titles titles2"
          v-if="notifications.length > 0"
        >
          Mensagens
        </p>
        <transition-group name="list-complete" tag="div" class="col-md-12 box">
          <notifica
            :item="item"
            v-for="(item, index) in notifications"
            class="list-complete-item"
            :key="index + 1"
            :index="index + 1"
          />
        </transition-group>
      </div>
    </div>

    <modal v-if="$store.getters.getUser.profile == 1" />
  </div>
</template>

<script>
import HTTP from "@/api_system";
import notifica from "@/components/notifica/notifica";
import modal from "@/components/notifica/modal";
import Recados from "@/components/recados/recados_vendedor.vue";
// import ModalImg from "@/components/recados/modal/imageModal.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    notifica,
    modal,
    Recados
    // ModalImg
  },
  data() {
    return {
      itemsNoti: [],
      urlImage: "",
      shows: false,
      sizeWindow: 0,
      link: ""
    };
  },
  mounted() {
    this.sizeWindow = $(window).width();
    this.getList();
    this.getAlert();
  },
  methods: {
    ...mapActions(["getAlert"]),
    showModal() {
      $("#modalNotifica").modal("show");
    },
    getList() {
      HTTP.get(`services/app/AlertNotice/GetListNoticesUser`, {
        headers: { Authorization: `Bearer ${this.$store.getters.getterToken}` }
      })
        .then(response => {
          this.itemsNoti = response.data.result;
        })
        .catch(err => console.log(err));
    },
    imagesCard(name, id, link) {
      this.shows = true;
      this.urlImage = name;
      this.link = link;
      HTTP.post(
        `services/app/AlertNotice/Create?NoticeId=${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getterToken}`
          }
        }
      )
        .then(response => {
          console.log(response); //! permissao
        })
        .catch(err => console.log(err));
    }
  },
  computed: {
    notifications() {
      return this.$store.getters.notificacaoTime.sort((a, b) => {
        return moment(b.creationTime) - moment(a.creationTime);
      });
    },
    ...mapGetters(["get_message"])
  },
  watch: {
    get_message: function() {
      if (this.get_message == 1 || this.get_message == 6) {
        this.getList();
        this.getAlert();
      }
    }
  }
};
</script>

<style scoped>
.notificacoes {
  background-color: #2474b2;
}
.btn_default {
  background-color: #e0a638;
  height: 40px;
  border: none;
}
.btn_default:hover {
  background-color: #f38235;
}
.data {
  color: #e0a638;
}

.titles {
  font-size: 25px;
}

.titles2 {
  margin: 0 0 18px 13px;
}

.box {
  margin-top: 35px;
}
@media screen and (max-width:767px) {
  .notificacoes{
    padding-top: 0!important;
    padding-left: 0;
    padding-right: 0;
  }
  .welcomeMobile{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    background: #217ABF;
    height: 40px;
  }
  .imgWelCont{
    width: 46%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .txtWelCont{
    width: 77%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .txtWelCont p{
    margin-bottom: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
  }
  .imgWelCont img{
    padding-left: 7%;
    width: 28%;
  }
  .main{
    padding-left: 15px;
    padding-right: 15px;
  }
  .container .main{
    margin: 0;
  }
  
}
</style>

<template>
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-6 mb-3">
        <div class="row">
          <div class="col-md-12 px-0">
            <label class="label_default">Sempre visível</label>
          </div>

          <div class="col-md-4 px-0" id="bottomCheck">
            <div class="d-flex align-items-center">
              <div class="checkbox">
                <input
                  type="checkbox"
                  id="visible"
                  name="visible"
                  :value="true"
                  :checked="visible"
                  @change="$emit('update:visible',$event.target.checked ? true: false)"
                />
                <label for="visible"></label>
              </div>
              <p class="mb-0 ml-2">Sim</p>
            </div>
          </div>
          <div class="col-md-4 px-0">
            <div class="d-flex align-items-center">
              <div class="checkbox">
                <input
                  type="checkbox"
                  name="noVisible"
                  :value="false"
                  :checked="!visible"
                  @change="$emit('update:visible',$event.target.checked ? false: true)"
                  id="noVisible"
                />
                <label for="noVisible"></label>
              </div>
              <p class="mb-0 ml-2">Não</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 mb-3">
        <div class="row">
          <div class="col-md-12 px-0">
            <label class="label_default">Urgência</label>
          </div>
          <div class="col-md-4 px-0" id="bottomCheck">
            <div class="d-flex align-items-center">
              <div class="checkbox">
                <input
                  type="checkbox"
                  name="type2"
                  :value="1"
                  @change="$emit('update:type',$event.target.checked ? 1: 0)"
                  :checked="type == 1"
                  id="type2"
                />
                <label for="type2"></label>
              </div>
              <p class="mb-0 ml-2">Regular</p>
            </div>
          </div>
          <div class="col-md-4 px-0">
            <div class="d-flex align-items-center">
              <div class="checkbox">
                <input
                  type="checkbox"
                  id="type1"
                  name="type1"
                  :value="0"
                  :checked="type == 0"
                  @change="$emit('update:type',$event.target.checked ? 0: 1)"
                />
                <label for="type1"></label>
              </div>
              <p class="mb-0 ml-2">Urgente</p>
            </div>
          </div>
        </div>
      </div>
      <transition name="vertical">
        <div class="col-md-12" v-show="!visible">
          <div class="row">
            <ValidationProvider
              class="col-md-6 pl-0"
              tag="div"
              :rules="!visible ? 'required|validaDataDe':''"
              v-slot="{ errors, ariaMsg, classes }"
              name="de"
            >
              <label for class="label_default" :class="classes">De</label>
              <input
                type="datetime-local"
                class="input_default form-control"
                name="de"
                :class="classes"
                :value="startDate"
                @input="$emit('update:startDate',$event.target.value)"
              />
              <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
            </ValidationProvider>
            <ValidationProvider
              class="col-md-6 pr-0"
              tag="div"
              :rules="!visible ? 'required|validaDataAte':''"
              name="ate"
              v-slot="{ errors, ariaMsg, classes }"
            >
              <label for class="label_default">Até</label>
              <input
                type="datetime-local"
                class="input_default form-control"
                name="ate"
                :value="endDate"
                @input="$emit('update:endDate',$event.target.value)"
                :class="classes"
                placeholder="##/##/####"
              />
              <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { extend } from "vee-validate";
export default {
  props: ["visible", "type", "startDate", "endDate"],

  data() {
    return {};
  },
  mounted() {
    this.registerValidations();
  },
  methods: {
    registerValidations() {
      extend("validaDataDe", {
        validate: this.validaDataDe,
        message: (field, params, data) => {
          return "Data invalida";
        }
      });
      extend("validaDataAte", {
        validate: this.validaDataAte,
        message: (field, params, data) => {
          return "Data invalida";
        }
      });
    },
    validaDataDe(val) {
      var retorno;
      var d = new Date();
      var now = moment(d).format();
      var data = moment(val).format();
      if (data != "Invalid date") {
        if (data > now) {
          retorno = true;
        } else {
          retorno = false;
        }
      } else {
        retorno = false;
      }
      return retorno;
    },
    validaDataAte(val) {
      var retorno;
      var now = moment(this.startDate).format();
      var data = moment(val).format();
      if (data != "Invalid date") {
        if (data >= now) {
          retorno = true;
        } else {
          retorno = false;
        }
      } else {
        retorno = false;
      }
      return retorno;
    }
  },
  watch: {
    startDate: function() {}
  }
};
</script>

<style scoped>
.label_default {
  font-size: 14px;
  color: #2474b2;
  font-weight: 500;
}
.input_default {
  width: 100%;
  text-align: center;

  font-weight: 500 !important;
}
#bottomCheck{
  margin-bottom: 5px;
}
</style>